import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-476cf600")
const _hoisted_1 = { class: "login-content" }
const _hoisted_2 = { class: "login" }
const _hoisted_3 = { class: "header" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_header = _resolveComponent("nav-header")!
  const _component_login_account = _resolveComponent("login-account")!
  const _component_public_bottom = _resolveComponent("public-bottom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_nav_header)
      ]),
      _createVNode(_component_login_account, { class: "login-accout" })
    ]),
    _createVNode(_component_public_bottom)
  ]))
}