
import { defineComponent } from 'vue'
import NavHeader from '@/components/nav-header'
import LoginAccount from './cpns/login-account.vue'

export default defineComponent({
  components: {
    NavHeader,
    LoginAccount
  },
  setup() {
    return {}
  }
})
