import { getCurrentInstance } from 'vue'

const proxy = getCurrentInstance()
const globalProperties = proxy?.appContext.config.globalProperties

// 编写规则
export const rules = {
  email: [
    {
      required: true,
      message: '',
      trigger: 'blur'
    },
    {
      pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: '',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '',
      trigger: 'blur'
    },
    {
      pattern: /^.{6,32}$/,
      message: '',
      trigger: 'blur'
    }
  ]
}
