
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { rules } from '../config/account-config'
import { ElForm } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useCurrentInstance } from '@/utils/public-methods'
import localCache from '@/utils/cache'

export default defineComponent({
  setup() {
    // input样式
    const style = {
      border: 'none',
      color: '#000',
      padding: 0,
      fontWeight: '700',
      width: '100%',
      fontSize: '16px',
      borderBottom: '1px solid #d8d8d8',
      marginBottom: '22px',
      borderRadius: '0'
    }

    const { proxy } = useCurrentInstance()

    rules.email.forEach((value, index) => {
      if (index === 0) {
        value.message = proxy?.$filters.hh_t('mustEmail')
      } else {
        value.message = proxy?.$filters.hh_t('enterValidEmail')
      }
    })

    rules.password.forEach((value, index) => {
      if (index === 0) {
        value.message = proxy?.$filters.hh_t('passwordNotEmpty')
      } else {
        value.message = proxy?.$filters.hh_t('loginPWDRule')
      }
    })

    const router = useRouter()
    const store = useStore()
    //响应式对象，传值的时候需要解构

    const {
      query: { redirect = '/' }
    } = useRoute()

    if (store.state.login.user) {
      router.push(decodeURIComponent(redirect as string))
    }

    const account = reactive({
      email: localCache.getCache('email') ?? '',
      password: localCache.getCache('password') ?? '',
      remember: true
    })

    const formRef = ref<InstanceType<typeof ElForm>>()

    const isKeepPassword = ref(true)
    const keepLogin = ref(true)

    const disabled = computed(() => {
      return !(account.email && account.password)
    })

    const accountLoginAction = () => {
      formRef.value?.validate((valid) => {
        if (valid) {
          // 1.判断是否要记住密码
          if (isKeepPassword.value) {
            // 本地缓存
            localCache.setCache('email', account.email)
            localCache.setCache('password', account.password)
          } else {
            localCache.deleteCache('email')
            localCache.deleteCache('password')
          }

          // 2.开始进行登录验证
          store.dispatch('login/accountLoginAction', {
            account: { ...account },
            redirect: redirect,
            router: router
          })
        }
      })
    }

    const registerClick = () => {
      router.push({ path: 'register' })
    }

    const forgetPwdClick = () => {
      router.push({ path: 'forgetpwd' })
    }

    return {
      account,
      formRef,
      rules,
      style,
      isKeepPassword,
      keepLogin,
      accountLoginAction,
      registerClick,
      forgetPwdClick,
      disabled
    }
  }
})
